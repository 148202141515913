export const formData = [
  {
    id: 1,
    name: "full_name",
    placeHolder: "Full Name *",
    size: "short",
    type: "text",
  },
  {
    id: 2,
    name: "email",
    placeHolder: "Email Address *",
    size: "short",
    type: "text",
  },
  {
    id: 3,
    name: "phone",
    placeHolder: "Phone Number *",
    size: "short",
    type: "phone",
  },
  {
    id: 4,
    name: "position",
    placeHolder: "Position Applied For *",
    size: "short",
    type: "select",
    items: [
      {
        id: 41,
        value: "All",
      },
      {
        id: 42,
        value: "Office",
      },
      {
        id: 43,
        value: "Purchasing",
      },
      {
        id: 44,
        value: "Sales",
      },
      {
        id: 45,
        value: "Warehousing",
      },
      {
        id: 46,
        value: "Driving",
      },
      {
        id: 47,
        value: "Other",
      },
    ],
  },
];
