export const slides = [
  {
    id: 1,
    img: require("../../assets/slideOne.png"),
    header: `<p>
        Leading the Way in Canada’s Asian Food Industry for 
        <span>Over 50 Years</span>
      </p>`,
    subText: `Discover delight with Toronto Sun Wah, your trusted partner for the finest Asian ingredients for your culinary success.`,
    ctaBttn: null,
  },
  {
    id: 2,
    img: require("../../assets/slideTwo.png"),
    header: `<p>
    <span> Authentic Ingredients </span> at
    <br>at Your Fingertips
    </p>`,
    subText: `Our extensive product catalog, meticulously curated to meet the needs of Asian cuisines at their finest`,
    ctaBttn: "Browse Our Catalog",
    url: "/catalog",
  },
  {
    id: 3,
    img: require("../../assets/slideThree.png"),
    header: `<p><span>
    Product Marketing Experts
    </span><p>`,
    subText: `Helping global brands and established manufacturers expand their market reach into Canada, one of the fastest-growing Asian populations in North America`,
    ctaBttn: "Talk to us now",
    url: "/contact",
    margin: "2%",
  },
  {
    id: 4,
    img: require("../../assets/slideFour.png"),
    header: `<p><span>
    Your Trusted Partner
    </span><p>`,
    subText: `Comprehensive product catalog, exceptional service, and a legacy of always putting clients first define our approach to meeting all Asian food distribution needs.`,
    ctaBttn: "Explore our site",
    url: "/catalog",
    margin: "6%",
  },
];
