import React from "react";
import "./ChooseUs.scss";
import { chooseUsData } from "./chooseUsData";
import { Swiper, SwiperSlide } from "swiper/react";
import { Button, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

const ChooseUs = () => {
  const isMobile = useMediaQuery("(max-width:700px)");
  const navigate = useNavigate();

  return (
    <motion.div
      className='choose-us-section'
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true, amount: 0.5 }}
      transition={{ ease: "easeOut", duration: 0.3 }}>
      <div className='wrapper'>
        <div className='text-wrapper'>
          <span className='section-title'>Why Choose Us</span>
          <p>What Sets Us Apart</p>
        </div>
        <div className='box-container'>
          {!isMobile &&
            chooseUsData.map((box, index) =>
              box.type !== "red-box" ? (
                <div className='box' key={index}>
                  <img src={box.img} alt='box-img-one' />
                  <div className='top-text-wrap'>
                    <p className='title-gray'>{box.title}</p>
                    <p className='red-text'>{box.redTxt}</p>
                  </div>
                  <p className='description'>{box.desc}</p>
                </div>
              ) : (
                <div className='box red' key={index}>
                  <img src={box.img} alt='box-img-one' />
                  <p className='title-txt'>{box.title}</p>
                  <Button onClick={() => navigate("/catalog")}>
                    Browse by category
                  </Button>
                </div>
              )
            )}
          {isMobile && (
            <Swiper slidesPerView={"auto"} spaceBetween={16}>
              {chooseUsData.map((box) =>
                box.type !== "red-box" ? (
                  <SwiperSlide>
                    <div className='box'>
                      <img src={box.img} alt='box-img-one' />
                      <div className='top-text-wrap'>
                        <p className='title-gray'>{box.title}</p>
                        <p className='red-text'>{box.redTxt}</p>
                      </div>
                      <p className='description'>{box.desc}</p>
                    </div>
                  </SwiperSlide>
                ) : (
                  <SwiperSlide>
                    <div className='box red'>
                      <img src={box.img} alt='box-img-one' />
                      <p className='title-txt'>{box.title}</p>
                      <Button>See All Categories</Button>
                    </div>
                  </SwiperSlide>
                )
              )}
            </Swiper>
          )}
        </div>
      </div>
    </motion.div>
  );
};

export default ChooseUs;
