import React, { useCallback, useState } from "react";
import {
  GoogleMap,
  Marker,
  useJsApiLoader,
  Polygon,
} from "@react-google-maps/api";
import { useMediaQuery } from "@mui/material";

const containerStyle = {
  width: "100%",
  height: "100%",
};

const mainCenter = {
  lat: 43.6228517,
  lng: -79.5118139,
};

const overlayCenter = {
  lat: 45.7695739,
  lng: -79.3862918,
};

const options = {
  fillColor: "#cb2727c7",
  fillOpacity: 0.5,
  strokeColor: "transparent",
};

const geojson = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      properties: {},
      geometry: {
        coordinates: [
          [-89.62973940507929, 48.54530724690207],
          [-89.43060548649946, 48.517746094836525],
          [-89.30543020132069, 48.462556207096924],
          [-89.21267931737431, 48.3949825852477],
          [-89.21284103948335, 48.26875998583279],
          [-89.21742431599509, 48.197823444771615],
          [-89.32844240110265, 48.16385207340906],
          [-89.4488719350276, 48.191645348327796],
          [-89.51372857065135, 48.25643034430533],
          [-89.57391653417022, 48.34268049686116],
          [-89.58329387400906, 48.39188112175225],
          [-89.64362636269578, 48.45022102617932],
          [-89.68993597528755, 48.49931232002231],
          [-89.63437081539549, 48.54837054332663],
        ],
        type: "LineString",
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        coordinates: [
          [-78.59058512944434, 46.209060101781006],
          [-78.98839046128178, 46.21027239719493],
          [-79.30908375242645, 46.11524996352932],
          [-79.66057666478189, 46.17001121698621],
          [-80.0584084569797, 46.14985647517249],
          [-80.51611348693046, 46.13627325030805],
          [-80.70249151586371, 46.169665940841206],
          [-80.91974950256494, 46.074544245640155],
          [-81.19078150443421, 46.05041600196657],
          [-81.40000870688736, 45.96192983091276],
          [-81.60990385377487, 45.94586139382076],
          [-81.72071864937865, 46.00502009069256],
          [-81.67703754363708, 46.05076643310046],
          [-81.63335643789553, 46.09651277550836],
          [-81.3813799681005, 46.24739086776272],
          [-81.3997297194126, 46.361301360156745],
          [-81.50933850384979, 46.455653572472094],
          [-81.56492181616915, 46.63019545314562],
          [-81.74045888392442, 46.71081074754581],
          [-81.76896301313616, 46.7990803845539],
          [-81.83329306185915, 46.72452326168563],
          [-81.9219955978616, 46.733274415026685],
          [-81.9717811355197, 46.80071143724193],
          [-82.09777716552244, 46.81644102673766],
          [-82.14401381242831, 46.725451534077635],
          [-82.18416172833369, 46.64685446732247],
          [-82.28889898342567, 46.68976272893866],
          [-82.27951903576314, 46.792056370995454],
          [-82.68349399337737, 46.877702514902815],
          [-82.84637997092949, 46.81658721627366],
          [-83.13567885660704, 46.93543019444745],
          [-83.39450168512766, 46.97417581684176],
          [-83.62612121393093, 46.77827958826907],
          [-83.87333371993154, 46.56734857852775],
          [-84.1340861090779, 46.53379704509034],
          [-84.29617209578981, 46.74737287309563],
          [-84.24392713482986, 46.880644090706284],
          [-84.33334513414691, 47.00139892160411],
          [-84.19183106358854, 47.098609366068104],
          [-83.99637177955829, 47.195689708132136],
          [-84.08721629221648, 47.82545613743835],
          [-84.17838872386291, 48.03150287920232],
          [-84.3799644806404, 48.28594688671703],
          [-84.74885927230186, 48.551718055202144],
          [-85.11538405782194, 48.54897752560973],
          [-85.36846744436461, 48.44653713749659],
          [-85.52989323532603, 48.2505470702377],
          [-85.59369298195563, 48.22809610137767],
          [-85.6295200334225, 48.167166202355446],
          [-85.69671582097858, 48.15858631107051],
          [-85.57913967460658, 48.44557690764634],
          [-85.76746083687546, 48.28926895133958],
          [-85.81859140362866, 48.33116093680909],
          [-85.82661703369844, 48.378282701214914],
          [-85.85030863817919, 48.41247517673672],
          [-85.80748032789896, 48.527805087528996],
          [-85.65088512649885, 48.64061803283818],
          [-85.98204196205982, 48.635966041690494],
          [-86.24352100750346, 48.70491216285495],
          [-86.42308052879415, 48.94606280498172],
          [-86.34293993024826, 49.18003265401515],
          [-86.06986118733796, 49.31924773075471],
          [-85.81034360297934, 49.37195354224565],
          [-85.7281547836631, 49.643141280603544],
          [-84.78637272616939, 49.565681779479206],
          [-84.89448416105337, 49.36878523425602],
          [-83.77510141148639, 49.35962681673655],
          [-83.75770613060526, 49.06606070182039],
          [-82.03079252719718, 49.114120070009506],
          [-81.93688260794136, 48.72183709152881],
          [-81.31431120581279, 48.62564791304004],
          [-81.39050969022115, 48.24931799733227],
          [-80.94370976124819, 48.06920905144494],
          [-80.56433258025424, 47.99879225487959],
          [-80.4355663671051, 48.00747392701703],
          [-80.26840474494219, 47.795605295364055],
          [-80.01143198302218, 47.580640696446295],
          [-80.02283821769707, 47.43488391011883],
          [-79.64818495349826, 47.34903380097106],
          [-79.65014190881118, 47.10585711320479],
          [-79.25614315240719, 47.05381926437278],
          [-79.15053038718021, 46.8177523213719],
          [-78.59468064472857, 46.205240208984605],
        ],
        type: "LineString",
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        coordinates: [
          [-82.78297292756253, 42.32494954477352],
          [-82.9693105656934, 42.36825565183136],
          [-83.1383479864503, 42.28644565547765],
          [-83.17669911245758, 42.17583894593193],
          [-83.10340229023478, 42.050519290092154],
          [-82.9367726534324, 41.97976643913901],
          [-82.76901224667245, 42.00586855039009],
          [-82.61969461475154, 42.0319149532412],
          [-82.50973604634731, 41.93330818868566],
          [-82.45106321529437, 42.085228682707424],
          [-82.16917893728521, 42.17806013858683],
          [-81.961158131557, 42.271503378617524],
          [-81.63686537350182, 42.459125452782274],
          [-81.29041028682546, 42.672896899011874],
          [-80.85736466576321, 42.63367939697338],
          [-80.53866453639334, 42.568532581256505],
          [-80.3263298530635, 42.644864481823134],
          [-80.05152605300535, 42.80189816675593],
          [-79.5726389752507, 42.82530433739538],
          [-79.20758201630707, 42.83776375972559],
          [-78.91704347211379, 42.866433030761016],
          [-79.04908257444224, 43.164661525137944],
          [-79.13716199834761, 43.27972243870542],
          [-79.42994225095589, 43.23635520781505],
          [-79.77297576890112, 43.29130224153229],
          [-79.6612833092049, 43.52294791989351],
          [-79.40144341897637, 43.637821151307804],
          [-79.14218187531856, 43.7245155268144],
          [-78.72445835795367, 43.81767665753418],
          [-78.44730113140595, 43.846683208535865],
          [-77.96313166996526, 43.962305537852416],
          [-77.58552939348124, 43.97043383228788],
          [-77.17447541035159, 43.90553594235956],
          [-76.97116804508909, 43.93562692009169],
          [-76.78843688333461, 44.12086007643603],
          [-76.44849041322063, 44.25439326378017],
          [-76.09087298020069, 44.34413351201269],
          [-75.80761879482924, 44.466356233142676],
          [-75.56064337413954, 44.60492063169093],
          [-75.27744302860435, 44.82576677001185],
          [-74.88468638082128, 44.92575934910849],
          [-74.40239416269054, 45.14652188306192],
          [-74.33712091871695, 45.26982951898671],
          [-74.35552345651881, 45.51333287584319],
          [-74.51114845753146, 45.59560054564881],
          [-74.72639499666957, 45.468277122972296],
          [-74.95323414190865, 45.38440011958994],
          [-75.25929111824422, 45.31984305578371],
          [-75.67382763190928, 45.34656394393576],
          [-75.97050876416523, 45.44956537654551],
          [-76.25273310665608, 45.50853196855934],
          [-76.54057121179977, 45.524836079709104],
          [-76.6006120386498, 45.68310788380424],
          [-76.77839102361423, 45.88990871672394],
          [-76.9784995784081, 45.942467529410294],
          [-76.93821178539493, 45.78548872781897],
          [-77.17690547613768, 45.85382994022274],
          [-76.97029484169981, 45.70269675702011],
          [-76.80321192796987, 45.56729627226002],
          [-76.57505138594814, 45.42819307570605],
          [-76.21393707199341, 45.351179516573865],
          [-76.00889949800717, 45.197911318052604],
          [-76.19142704522454, 45.082155773600874],
          [-76.35420508871461, 44.95117382071234],
          [-76.49731582711144, 44.80504560899382],
          [-76.93458844383984, 44.73200269766764],
          [-77.3365375343443, 44.585975080807685],
          [-77.97571451338733, 44.42314412585992],
          [-78.24290316137552, 44.338377548399905],
          [-78.62409880101876, 44.286120245275185],
          [-78.95818805156318, 44.300356718270336],
          [-79.13830007377358, 44.502111470697315],
          [-79.40575574342155, 44.60944167316805],
          [-79.65184046993775, 44.74307494731434],
          [-79.84351761531906, 44.818838594329605],
          [-80.07957641088416, 44.82626373239424],
          [-80.11139559371242, 44.647026246751494],
          [-80.060090185783, 44.53457030667994],
          [-80.29899862716292, 44.47258214014008],
          [-80.5639365952057, 44.6185262956443],
          [-80.94852829624226, 44.7389779997479],
          [-81.10067848231324, 44.825480033610205],
          [-81.07413256296549, 44.96327670566032],
          [-81.26751220750235, 45.02277261862565],
          [-81.30020737236197, 45.16186759414029],
          [-81.49309781813014, 45.24865663469595],
          [-81.67015813268966, 45.25179154390196],
          [-81.5961392708158, 45.139776860685856],
          [-81.44581024688512, 44.984802694031515],
          [-81.33713395200847, 44.78901971048785],
          [-81.32943063696965, 44.52620446671446],
          [-81.45238444252108, 44.37673171032199],
          [-81.63407155821642, 44.186912852163886],
          [-81.7359782335626, 44.0369286447409],
          [-81.70586020798, 43.80168385691837],
          [-81.72009418527364, 43.38768235005685],
          [-81.87748606647301, 43.224842755104504],
          [-82.05095510244504, 43.131130938654564],
          [-82.24438731397242, 42.96836066599613],
          [-82.41522444751321, 42.92931765355732],
          [-82.49347040635094, 42.7921145504159],
          [-82.55360129606706, 42.59923681457073],
          [-82.44320679699268, 42.48708065005829],
          [-82.46439510552239, 42.36271892474588],
          [-82.74559739129876, 42.32455596390358],
          [-82.96981893343099, 42.32670986838173],
        ],
        type: "LineString",
      },
    },
  ],
};

const convertCoordinates = (geojson) => {
  return geojson.features.map((feature) => {
    return feature.geometry.coordinates.map((coord) => {
      return { lat: coord[1], lng: coord[0] };
    });
  });
};

const paths = convertCoordinates(geojson);

const GoogleMapComponent = ({ overlay }) => {
  const [map, setMap] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [defaultCenter, setDefaultCenter] = useState(
    overlay ? overlayCenter : mainCenter
  );
  const [initialZoom, setInitialZoom] = useState(!overlay ? 15 : 6);
  const mobile = useMediaQuery("(max-width:600px)");

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  const onLoad = useCallback((map) => {
    setMap(map);
    map.setCenter(defaultCenter);
    map.setZoom(initialZoom);
  }, []);

  const onUnmount = useCallback((map) => {
    setMap(null);
  }, []);

  return (
    <div className="map-container-google">
      <div className="wrapper" style={{ height: overlay ? "650px" : "450px" }}>
        {isLoaded && (
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={defaultCenter}
            zoom={initialZoom}
            options={{
              fullscreenControl: false,
            }}
            onLoad={onLoad}
            onUnmount={onUnmount}>
            {overlay ? (
              <>
                <Polygon paths={paths} options={options} />
                <Marker
                  key={3}
                  position={{ lat: 48.38, lng: -80.01 }}
                  icon={{
                    url: require("../assets/ourframes.webp"),
                    scaledSize: new window.google.maps.Size(100, 50),
                  }}
                />
              </>
            ) : (
              <>
                <Marker
                  key={1}
                  position={defaultCenter}
                  icon={{
                    url: require("../assets/pin.webp"),
                    scaledSize: new window.google.maps.Size(58, 77),
                  }}
                />
                <Marker
                  key={2}
                  position={{ lat: 43.6182, lng: -79.5078 }}
                  icon={{
                    url: require("../assets/infoWindow.webp"),
                    scaledSize: new window.google.maps.Size(
                      !mobile ? 314 : 252,
                      !mobile ? 172 : 144
                    ),
                  }}
                />
              </>
            )}
          </GoogleMap>
        )}
      </div>
    </div>
  );
};

export default GoogleMapComponent;
