import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: {
    zh: {
      translation: {
        Home: "首页",
        Company: "公司",
        Catalog: "目录",
        "Request for Pricing": "询价",
        "Dairy & Egg": "乳制品和鸡蛋",
        Brands: "品牌",
        Contact: "接触",
        Careers: "职业机会",
        Grocery: "杂货店",
      },
    },
  },
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
