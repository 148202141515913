import React, { useState, useEffect } from "react";
import "./Careers.scss";
import careersOne from "../../assets/careers1.png";
import careersTwo from "../../assets/careers2.png";
import pdf from "../../assets/pdf.svg";
import word from "../../assets/word.svg";
import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { formData } from "./Form";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import uploadIcon from "../../assets/icons.svg";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { emailjsSend } from "../../emailjsConfig";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { storage } from "../../firebaseConfig";
import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import xiaohongshu from "../../assets/xiaohongshu.svg";
import Instagram from "../../assets/Instagram.svg";

const Careers = () => {
  const [data, setData] = useState({});
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const isMobile = useMediaQuery("(max-width:620px)");
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(true);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const checkIsValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
  const [file, setFile] = useState();
  const [isUploading, setIsUploading] = useState(false);
  const [fileName, setFileName] = useState("");
  const [fileExtension, setFileExtension] = useState("");
  const notify = () => toast("Only PDF, DOC, and DOCX files are allowed.");
  const notifyEmail = () => toast("Please Enter a valid Email Address");

  const handleChange = (e) => {
    const { value, name } = e.target;
    setData({ ...data, [name]: value });
    if (name === "email") {
      if (value.match(checkIsValidEmail)) {
        setIsValidEmail(true);
      } else {
        setIsValidEmail(false);
      }
    }
  };

  const phoneChange = (value) => {
    setData({ ...data, phone: value });
  };
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const [isDescriptioinFocused, setIsDescriptioinFocused] = useState(false);

  const handleDescriptioinFocus = () => {
    setIsDescriptioinFocused(true);
  };

  const handleDescriptioinBlur = () => {
    setIsDescriptioinFocused(false);
  };

  const handleFileChange = async (event) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }

    const name = fileObj.name;
    const extension = name.split(".").pop().toLowerCase();
    setFileName(name);
    setFileExtension(extension);

    if (!["pdf", "doc", "docx"].includes(extension)) {
      toast.error("Only PDF, DOC, and DOCX files are allowed.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      setFile(null);
      return;
    }

    setFile(fileObj);

    try {
      setIsUploading(true);
      const storageRef = ref(storage, fileObj.name);
      await uploadBytes(storageRef, fileObj);
      const firebaseURL = await getDownloadURL(storageRef);
      if (firebaseURL) {
        setData({ ...data, resume: firebaseURL });
      }
      setIsUploading(true);
    } catch (err) {
      setIsUploading(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setDisabled(true);
    const res = await emailjsSend(data, "template_sze5osp");
    if (res) {
      setError(false);
      setSuccess(true);
    } else {
      setError(true);
    }
  };
  const [toastShown, setToastShown] = useState(false);

  useEffect(() => {
    const validate = () => {
      if (
        data.full_name &&
        data.phone &&
        data.email &&
        data.position &&
        data.description &&
        data.resume &&
        data.linkedin_link &&
        isValidEmail
      ) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    };
    // Show email warning if the email is invalid
    if (!isValidEmail && data.email && !toastShown) {
      setTimeout(() => {
        toast.error("Please Enter a valid Email Address", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      }, 2000);
      setToastShown(true);
      setTimeout(() => {
        setToastShown(false);
      }, 4000);
    }
    validate();
    // console.log(data);
  }, [data, isValidEmail]);

  return (
    <div className='Careers-page'>
      <div className='header-container'>
        <div className='wrapper'>
          <h1>Be Part of Our Team of Food Aficionados</h1>
          <motion.p
            initial={{ opacity: 0, x: 50 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true, amount: 0.5 }}
            transition={{ ease: "easeOut", duration: 0.3 }}>
            Here at Toronto Sun Wah, we have spent more than 50 years building a
            business and a family. We offer a welcoming environment and a
            rewarding experience.
            <br></br>
            {/* <br></br> */}
            Share our love for food and our desire to make a lasting impact in
            our communities.
            <br></br>
            Apply within.
          </motion.p>
        </div>
      </div>
      <motion.div
        className='opportunities-sec'
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true, amount: isMobile ? 0.01 : 0.2 }}
        transition={{ ease: "easeOut", duration: 0.3 }}>
        <div className='wrapper'>
          <div className='sec-header'>
            <span className='red-txt'>Career Opportunities</span>
            <h2>Explore a Future with Us</h2>
            <p>
              We are seeking passionate individuals eager to drive change and
              make a lasting impact in our communities with Toronto Sun Wah.
            </p>
          </div>
          <div className='contact-sec'>
            <div className='img-wrap'>
              <img src={careersOne} alt='professional-man' />
            </div>
            {success ? (
              <div className='success-div'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='103'
                  height='103'
                  viewBox='0 0 103 103'
                  fill='none'>
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M94.4164 51.4999C94.4164 75.2021 75.2019 94.4166 51.4997 94.4166C27.7975 94.4166 8.58301 75.2021 8.58301 51.4999C8.58301 27.7977 27.7975 8.58325 51.4997 8.58325C75.2019 8.58325 94.4164 27.7977 94.4164 51.4999ZM68.7965 38.4948C70.0535 39.7518 70.0535 41.7898 68.7965 43.0468L47.3382 64.5051C46.0812 65.7621 44.0432 65.7621 42.7862 64.5051L34.2028 55.9218C32.9458 54.6648 32.9458 52.6268 34.2028 51.3698C35.4598 50.1128 37.4978 50.1128 38.7548 51.3698L45.0622 57.6771L54.6533 48.0859L64.2445 38.4948C65.5015 37.2378 67.5395 37.2378 68.7965 38.4948Z'
                    fill='#CB2727'
                  />
                </svg>
                <p className='success-p'>Success!</p>
                <p className='desc'>
                  Thank you for your interest in a position at Toronto Sun Wah.
                  We will review your submission and contact you if your
                  qualifications meet our current needs.
                </p>
              </div>
            ) : (
              <div className='form-wrap'>
                <div className='title'>
                  <h3>Apply Now</h3>
                  <span>
                    Fill out the form below to submit your application.
                  </span>
                </div>
                <div className='form'>
                  {formData.map(
                    (input) =>
                      (input.type === "text" && (
                        <TextField
                          disabled
                          key={input.id}
                          id='outlined-basic'
                          label={input.placeHolder}
                          name={input.name}
                          type={input.name === "email" ? "email" : "text"}
                          variant='outlined'
                          onChange={handleChange}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "12px", // Apply border radius to the input
                              "&.Mui-focused": {
                                "& fieldset": {
                                  borderColor: "#cb2727", // Custom focus border color
                                },
                              },
                            },
                            "& .MuiInputLabel-root": {
                              "&.Mui-focused": {
                                color: "#cb2727", // Custom focus label color
                              },
                            },
                            width:
                              input.size === "short"
                                ? "calc(50% - 10px)"
                                : "100%",
                          }}
                        />
                      )) ||
                      (input.type === "select" && (
                        <FormControl
                          disabled
                          key={input.id}
                          sx={{
                            width:
                              input.size === "short"
                                ? !isMobile
                                  ? "calc(50% - 10px)"
                                  : "100%"
                                : "100%",
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "12px", // Apply border radius to the input
                              "&.Mui-focused": {
                                "& fieldset": {
                                  borderColor: "#cb2727", // Custom focus border color
                                },
                              },
                            },
                            "& .MuiInputLabel-root": {
                              "&.Mui-focused": {
                                color: "#cb2727", // Custom focus label color
                              },
                            },
                          }}>
                          <InputLabel id='demo-simple-select-label'>
                            {input.placeHolder}
                          </InputLabel>
                          <Select
                            value={data?.position || ""}
                            name={input.name}
                            label={input.placeHolder}
                            onChange={handleChange}>
                            <MenuItem value=''>None</MenuItem>
                            {input.items.map((item) => (
                              <MenuItem value={item.value} key={item.id}>
                                {item.value}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )) ||
                      (input.type === "phone" && (
                        <div className='phone-input-container'>
                          <div
                            className={`phone-input ${
                              isFocused || phoneNumber ? "Mui-focused" : ""
                            }`}>
                            <PhoneInput
                              disabled
                              name='phone'
                              country='CA'
                              defaultCountry='CA'
                              value={phoneNumber}
                              onChange={(value) => phoneChange(value)}
                              onFocus={handleFocus}
                              onBlur={handleBlur}
                            />
                            <label
                              htmlFor='phone'
                              className={`input-label ${
                                isFocused || phoneNumber ? "shrink" : ""
                              }`}>
                              Phone Number *
                            </label>
                          </div>
                        </div>
                      ))
                  )}
                  <div className='text-area-wrapper'>
                    {!data.description || data.description.length === 0 ? (
                      <div className='custom-placeholder'>
                        <br />
                        Tell us about yourself and why you're interested in
                        joining Toronto Sun Wah Trading.
                      </div>
                    ) : null}
                    <div
                      className={`text-area-input ${
                        isDescriptioinFocused || data.description
                          ? "Mui-focused"
                          : ""
                      }`}>
                      <textarea
                        disabled
                        name='description'
                        value={data.description || ""}
                        onChange={handleChange}
                        onFocus={handleDescriptioinFocus}
                        onBlur={handleDescriptioinBlur}
                      />
                      <label
                        htmlFor='description'
                        className={`input-label ${
                          isDescriptioinFocused || data.description
                            ? "shrink"
                            : ""
                        }`}>
                        Description
                      </label>
                    </div>
                  </div>
                  <div className='unpload-div'>
                    {!isUploading ? (
                      <>
                        <Button
                          component='label'
                          className='upload-bttn'
                          disabled>
                          <input
                            type='file'
                            name='resume'
                            className='upload-div'
                            onChange={handleFileChange}
                            accept='.pdf,.doc,.docx'
                          />
                          <img src={uploadIcon} alt='icon' />
                          Upload Resume
                        </Button>
                        <span className='file-types'>
                          Attach your resume here. Accepted formats: PDF, DOC,
                          DOCX
                        </span>
                        <ToastContainer />
                      </>
                    ) : (
                      <div className='filename'>
                        <div className='name'>
                          <img
                            src={
                              fileExtension.toLowerCase() === "pdf" ? pdf : word
                            }
                            alt='pdf icon'
                          />
                          <p>{fileName}</p>
                        </div>
                        <svg
                          className='closeicon'
                          onClick={() => setIsUploading(false)}
                          xmlns='http://www.w3.org/2000/svg'
                          width='20'
                          height='21'
                          viewBox='0 0 20 21'
                          fill='none'>
                          <path
                            d='M15 5.5L5 15.5M5 5.5L15 15.5'
                            stroke='#9B9B9B'
                            strokeWidth='2'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </div>
                    )}
                  </div>
                  <TextField
                    disabled
                    id='outlined-basic'
                    label='LinkedIn Link'
                    name='linkedin_link'
                    variant='outlined'
                    onChange={handleChange}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "12px", // Apply border radius to the input
                        "&.Mui-focused": {
                          "& fieldset": {
                            borderColor: "#cb2727", // Custom focus border color
                          },
                        },
                      },
                      "& .MuiInputLabel-root": {
                        "&.Mui-focused": {
                          color: "#cb2727", // Custom focus label color
                        },
                      },
                      width: "100%",
                    }}
                  />
                  <Button
                    className='submit-bttn'
                    onClick={handleSubmit}
                    disabled={disabled}>
                    Submit
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </motion.div>
      <motion.div
        className='accessiblity-sec'
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true, amount: isMobile ? 0.01 : 0.5 }}
        transition={{ ease: "easeOut", duration: 0.3 }}>
        <div className='wrapper'>
          <div className='text-wrap'>
            <p className='big-txt'>
              We’re Committed to Accessibility and Equality
            </p>
            <span className='desc'>
              Toronto Sun Wah is committed to creating an inclusive and
              accessible workplace.
              <br></br>
              <br></br>
              We value diversity and provide equal opportunities for all
              applicants. For more information, please see our Multi-Year
              Accessibility Plan (MYAP).
            </span>
            <Button
              className='submit-bttn'
              onClick={() => navigate("/multiYear")}>
              View Our MYAP
            </Button>
            <div className='social-div'>
              <p className='txt'>Follow Us</p>
              <a
                href='https://www.linkedin.com/company/tswtrading/'
                target='_blank'
                rel='noopener noreferrer'>
                <IconButton>
                  <LinkedInIcon
                    style={{ color: "#CB2727", fontSize: "40px" }}
                  />
                </IconButton>
              </a>

              <a
                href='https://www.facebook.com/tswtrading/'
                target='_blank'
                rel='noopener noreferrer'>
                <IconButton style={{ width: "fit-content" }}>
                  <FacebookIcon
                    style={{ color: "#CB2727", fontSize: "40px" }}
                  />
                </IconButton>
              </a>

              <a
                href='https://www.instagram.com/tswtrading/'
                target='_blank'
                rel='noopener noreferrer'>
                <IconButton style={{ width: "fit-content" }}>
                  <img src={Instagram} alt='instagram' />
                </IconButton>
              </a>
              <a
                href='https://www.xiaohongshu.com/user/profile/65c39db30000000011019140'
                target='_blank'
                rel='noopener noreferrer'>
                <IconButton style={{ width: "fit-content" }}>
                  <img src={xiaohongshu} alt='xiaohongshu' />
                </IconButton>
              </a>
            </div>
          </div>
          <div className='img-wrap'>
            <div className='back-layer'></div>
            <img src={careersTwo} alt='img' />
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default Careers;
