import React, { useEffect } from "react";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import { useLocation } from "react-router-dom";

const Layout = ({ children, categories }) => {
  const { pathname, key } = useLocation();
  const [Include, setInclude] = React.useState(true);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    if (pathname === "/quote") {
      setInclude(false);
    } else {
      setInclude(true);
    }
  }, [pathname, key]);

  return (
    <>
      {Include && <Header categories={categories} />}
      {children}
      {Include && <Footer />}
    </>
  );
};

export default Layout;
