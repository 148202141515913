import React from "react";
import "./ContactSection.scss";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import TorontoMapIframe from "../../TorontoMapIframe";

const ContactSection = () => {
  const navigate = useNavigate();

  return (
    <div className='Contact-Section-component'>
      <div className='title-div'>
        <p>Get in Touch with Us</p>
        <Button className='contact-bttn' onClick={() => navigate("/contact")}>
          Contact Us
        </Button>
      </div>
      <div className='map-section'>
        {/* <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2957539.4302771995!2d-82.14609495036201!3d43.62273609999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b362d8fa332ef%3A0x55d7c5ef65a7ac73!2s18%20Canmotor%20Ave%2C%20Toronto%2C%20ON%20M8Z%204E5%2C%20Canada!5e0!3m2!1sen!2seg!4v1716531403048!5m2!1sen!2seg"
          width="100%"
          height="600"
          style={{ border: 0 }}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"></iframe> */}
        <TorontoMapIframe overlay={true} />
      </div>
      <motion.div
        className='contact-details-container'
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true, amount: 0.5 }}
        transition={{ ease: "easeOut", duration: 0.3 }}>
        <div className='contact-overflow-div'>
          <div className='contact-box'>
            <p className='title'>Call Us</p>
            <p className='info'>(416) 252-7757</p>
            {/* <p className='bottom-text'>
              Call Us for Inquiries, Orders,<br></br> or Support
            </p> */}
          </div>
          <div className='contact-box'>
            <p className='title'>Email Us</p>
            <p className='info'>info@tswtrading.com</p>
            {/* <p className='bottom-text'>
              For questions or more information, drop us<br></br> a line
              anytime.{" "}
            </p> */}
          </div>
          <div className='contact-box'>
            <p className='title'>Visit Us</p>
            <p className='info'>18 Canmotor Ave, Etobicoke, ON M8Z 4E5</p>
            {/* <p className='bottom-text'>
              For questions or more information, drop us<br></br> a line
              anytime.{" "}
            </p> */}
          </div>
          <div className='contact-box'>
            <p className='title'>Hours</p>
            <p className='info'>
              Weekdays: 9:00 AM - 5:00 PM<br></br> Weekends: Closed
            </p>
            <p className='bottom-text'>
              Please note that hours may change due to statutory holidays.
            </p>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default ContactSection;
