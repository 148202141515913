export const Brands = [
  {
    id: 1,
    name: "ADM",
    src: require("../../../assets/brand1.png"),
  },
  {
    id: 2,
    name: "Ingredion",
    src: require("../../../assets/Ingredion.png"),
  },
  {
    id: 3,
    name: "Bunge",
    src: require("../../../assets/brand3.jpg"),
  },
  {
    id: 4,
    name: "Planway Poultry",
    src: require("../../../assets/PlanwayPoultry.png"),
  },
  {
    id: 5,
    name: "Dek Som Boon",
    src: require("../../../assets/DekSomBoon.png"),
  },
  {
    id: 6,
    name: "Hai’s",
    src: require("../../../assets/brand6.png"),
  },
  {
    id: 7,
    name: "Better Box",
    src: require("../../../assets/BetterBox.png"),
  },
  {
    id: 8,
    name: "Dainty",
    src: require("../../../assets/Dainty.png"),
  },
  {
    id: 9,
    name: "Bangkok Elephant",
    src: require("../../../assets/brand9.png"),
  },
  {
    id: 10,
    name: "Lee Kum Kee",
    src: require("../../../assets/brand10.png"),
  },
  {
    id: 11,
    name: "Royal Lion",
    src: require("../../../assets/RoyalLion.png"),
  },
  {
    id: 12,
    name: "Double Elephant",
    src: require("../../../assets/brand8.png"),
  },
];
