export const chooseUsData = [
  {
    id: 1,
    img: require("../../assets/box1.png"),
    title: "Pioneering Innovation",
    redTxt: "Evolving for Excellence",
    desc: "Our commitment to innovation ensures we continually evolve, bringing you superior service and the latest in the food industry.",
  },
  {
    id: 2,
    img: require("../../assets/box2.png"),
    title: "Market Insights and Food Trends",
    redTxt: "Ahead of the Curve",
    desc: "With our deep insights into market trends and evolving food preferences, we help you stay ahead in the culinary world.",
  },
  {
    id: 3,
    img: require("../../assets/box3.png"),
    title: "Proven Product Expertise",
    redTxt: "Your Culinary Consultants",
    desc: "Rely on our experienced sales team's product expertise to find the perfect ingredients for your needs.",
  },
  {
    id: 4,
    img: require("../../assets/box4.png"),
    title: "Operational Excellence",
    redTxt: "Decades of Trusted Service",
    desc: "With 50 years of experience, our operational excellence ensures reliability and peace of mind in every transaction.",
  },
  {
    id: 5,
    img: require("../../assets/box5.png"),
    title: "Trusted Advisors With First-Class Service",
    redTxt: "Your Culinary Journey, Elevated",
    desc: "Beyond a supplier, we're your trusted advisors, committed to excellence in every interaction. Expect first-class service that attentively meets your needs, big or small, at every step of your journey with us.",
  },
  {
    id: 6,
    img: require("../../assets/box6.png"),
    title: "Unmatched Customer Service",
    redTxt: "Dedicated Team, Personalized Service",
    desc: "Boasting the region's largest dedicated foodservice sales team, we go beyond the norm to offer personalized, hands-on service, setting us apart from other distributors.",
  },
  {
    id: 7,
    img: require("../../assets/box7.png"),
    title: "Best-in-Class Distribution",
    redTxt: "Efficient, Reliable Delivery",
    desc: "Experience seamless distribution with our expansive network, delivering right to your doorstep across the GTHA and Southern Ontario.",
  },
  {
    id: 8,
    type: "red-box",
    img: require("../../assets/box8.png"),
    title: "Explore Our Diverse Selection",
  },
];
