// store/cartStore.js
import { create } from "zustand";
import { persist } from "zustand/middleware"; // Import the persist middleware

const useCartStore = create(
  persist(
    (set) => ({
      cart: [],
      addToCart: (product, quantity) =>
        set((state) => {
          const existingItem = state.cart.find(
            (item) => item.product.ProductCode === product.ProductCode
          );
          if (existingItem) {
            return {
              cart: state.cart.map((item) =>
                item.product.ProductCode === product.ProductCode
                  ? { ...item, quantity: item.quantity + quantity }
                  : item
              ),
            };
          } else {
            return {
              cart: [...state.cart, { product, quantity }],
            };
          }
        }),
      removeFromCart: (productCode) =>
        set((state) => ({
          cart: state.cart.filter(
            (item) => item.product.ProductCode !== productCode
          ),
        })),
      updateQuantity: (productCode, quantity) =>
        set((state) => ({
          cart: state.cart.map((item) =>
            item.product.ProductCode === productCode
              ? { ...item, quantity }
              : item
          ),
        })),
      clearCart: () => set({ cart: [] }), // Clear the entire cart
    }),
    {
      name: "cart-storage", // Key to store the cart in localStorage
      getStorage: () => localStorage, // Specify localStorage as the storage solution
    }
  )
);

export default useCartStore;
