import React, { useEffect, useRef, useState } from "react";
import "./OurStory.scss";
import OurStoryImg from "../assets/ourstory.svg";
import foodImage from "../assets/food.png";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

const OurStory = () => {
  const pizzaImg = useRef();
  const [imgWrapperHeight, setImgWrapperHeight] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const updateWrapperHeight = () => {
      const imgHeight = pizzaImg.current.offsetHeight;
      setImgWrapperHeight(imgHeight * 0.5);
    };

    // Initialize ResizeObserver
    const resizeObserver = new ResizeObserver(updateWrapperHeight);

    // Observe changes in the pizzaImg element
    if (pizzaImg.current) {
      resizeObserver.observe(pizzaImg.current);
    }

    // Initial update of wrapper height
    updateWrapperHeight();

    // Cleanup ResizeObserver
    return () => {
      resizeObserver.disconnect();
    };
  }, []);
  return (
    <div className='our-story-section'>
      <div className='wrapper'>
        <motion.div
          className='text-wrap'
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true, amount: 0.5 }}
          transition={{ ease: "easeOut", duration: 0.3 }}>
          <span className='section-title'>Our Story</span>
          <p className='header'>
            Pioneering and Leading <br></br> Importer, Producer, and Distributor
            <br />
            in Canada
          </p>
          <p className='description'>
            Since 1973, Toronto Sun Wah has been at the forefront of the Asian
            food industry. With a legacy rooted in always putting clients first,
            we have grown to become more than a distributor – we’re your
            strategic partner for business success.
          </p>
          <Button onClick={() => navigate("/company")}>Learn More</Button>
          <img src={OurStoryImg} alt='our-story-img' className='absolute-img' />
        </motion.div>
        <motion.div
          className='img-wrap-story'
          initial={{ opacity: 0, x: 50 }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true, amount: 0.5 }}
          transition={{ ease: "easeOut", duration: 0.3, delay: 0.2 }}>
          <div
            className='mobile-wrapper'
            style={{
              height: imgWrapperHeight,
            }}></div>
          <img src={foodImage} alt='food-img' ref={pizzaImg} />
        </motion.div>
      </div>
    </div>
  );
};

export default OurStory;
