import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyA4PTlM677B2tSUgjGtcXl28vuHNYgcXyI",
  authDomain: "tswtrading-83949.firebaseapp.com",
  projectId: "tswtrading-83949",
  storageBucket: "tswtrading-83949.appspot.com",
  messagingSenderId: "949528606142",
  appId: "1:949528606142:web:b5e605345b8dd77cfd75ef",
  measurementId: "G-HLLVS1G0MG",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const storage = getStorage(app);
// const auth = getAuth(app);
export { storage };
