import axios from "axios";

// Function to fetch products from Unleashed API from multiple URLs
export const fetchProducts = async () => {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    "api-auth-id": "799a0c17-1dd1-48d6-a73d-fe26578dd781",
    "api-auth-signature": "cTV8oYqNMSzPC18sYGjXZ6mD8vKPhmEbgCyOa2+1qKI=",
    "client-type": "tsw/PAGetProductsFromUnleashed",
  };

  // Define the 3 URLs to fetch products from
  const urls = [
    `https://api.unleashedsoftware.com/Products/1?pageSize=1000&includeObsolete=true&includeAttributes=true`,
    `https://api.unleashedsoftware.com/Products/2?pageSize=1000&includeObsolete=true&includeAttributes=true`,
    `https://api.unleashedsoftware.com/Products/3?pageSize=1000&includeObsolete=true&includeAttributes=true`,
  ];

  try {
    // Make concurrent requests to all three URLs using Promise.all
    const responses = await Promise.all(
      urls.map((url) => axios.get(url, { headers }))
    );

    // Combine all the product data into one array
    const allProducts = responses.reduce((acc, response) => {
      return acc.concat(response.data.Items); // Assuming 'Items' holds the products
    }, []);

    // Filter products where IsPublished is "Yes"
    const publishedProducts = allProducts.filter((product) => {
      const isPublishedAttr = product.AttributeSet.Attributes.find(
        (attr) => attr.Name === "IsPublished"
      );
      return isPublishedAttr && isPublishedAttr.Value === "Yes";
    });

    return publishedProducts; // Return the filtered list of products
  } catch (error) {
    console.error("Error fetching products", error);
    throw error;
  }
};

// Fetch local JSON data dynamically
export const fetchLocalProducts = async () => {
  try {
    const data = await import("./productsData.json");

    // Filter products where IsPublished is "Yes"
    const publishedLocalProducts = data.Items.filter((product) => {
      const isPublishedAttr = product.AttributeSet.Attributes.find(
        (attr) => attr.Name === "IsPublished"
      );
      return isPublishedAttr && isPublishedAttr.Value === "Yes";
    });

    return publishedLocalProducts; // Return the filtered list of local products
  } catch (error) {
    console.error("Error importing local products", error);
    return null;
  }
};

// Function to fetch categories
export const fetchCategories = async () => {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    "api-auth-id": "799a0c17-1dd1-48d6-a73d-fe26578dd781",
    "api-auth-signature": "riC1qujXgpxVhYbjXRZDOEG2gWaNVxLMn6/HNTQCD4k=",
    "client-type": "tsw/PAGetProductsFromUnleashed",
  };

  try {
    const response = await axios.get(
      `https://api.unleashedsoftware.com/ProductGroups/1?pageSize=1000`, // Replace with correct endpoint for categories
      { headers }
    );

    return response.data.Items; // Assuming categories are inside 'Items'
  } catch (error) {
    console.error("Error fetching categories", error);
    throw error;
  }
};

// Save products to localStorage
export const saveProductsToStorage = (products) => {
  const subset = products.map(
    ({
      ProductDescription,
      ProductCode,
      ImageUrl,
      Images,
      AttributeSet,
      ProductGroup,
    }) => ({
      ProductDescription,
      ProductCode,
      ImageUrl,
      Images,
      AttributeSet,
      ProductGroup,
    })
  ); // Save only ID and name
  localStorage.setItem("cachedProducts", JSON.stringify(subset));
};

// Get cached products from localStorage
export const getCachedProducts = () => {
  const cachedProducts = localStorage.getItem("cachedProducts");
  return cachedProducts ? JSON.parse(cachedProducts) : null;
};

// Start periodic fetching and caching of products
export const startFetchingProducts = () => {
  const fetchAndCacheProducts = async () => {
    const products = await fetchProducts();
    saveProductsToStorage(products);
  };

  // Fetch products from the API immediately after loading
  fetchAndCacheProducts();

  // Set up interval to fetch products every 15 minutes
  setInterval(fetchAndCacheProducts, 15 * 60 * 1000); // 15 minutes in milliseconds
};
