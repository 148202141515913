import React from "react";
import "./PartnersSwipper.scss";
import "swiper/css";
import { Partners } from "../../Pages/Home/Partners";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, FreeMode } from "swiper/modules";

const PartnersSwipper = () => {
  return (
    <div className='partenrs-swipper-wrapper'>
      <Swiper
        spaceBetween={62}
        centeredSlides={true}
        autoplay={{
          delay: 0,
          disableOnInteraction: false,
          pauseOnMouseEnter: false,
        }}
        modules={[Autoplay, FreeMode]}
        slidesPerView={"auto"}
        loop={true}
        freeMode={true}
        speed={6000}
        allowTouchMove={false} // Disable touch/drag interaction
        touchRatio={0} // Prevents any touch or click interruption
        style={{ width: "100%", height: "111px" }}>
        {Partners.map((partner, index) => (
          <SwiperSlide key={index}>
            <img src={partner.img} className='partner-img' />
          </SwiperSlide>
        ))}
      </Swiper>
      <div className='absolute-opacity-right'></div>
      <div className='absolute-opacity-left'></div>
    </div>
  );
};

export default PartnersSwipper;
