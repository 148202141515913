import React, { useEffect, useState } from "react";
import "./Home.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import { Avatar, Button, Rating } from "@mui/material";
import { slides } from "./SwipperData";
import "swiper/css/navigation";
import "swiper/css/pagination";
import CategoriesFilter from "../../Components/CatgegoriesFilter/CategoriesFilter";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ShopBrands from "../../Components/ShopCompnents/ShopBrands/ShopBrands";
import ShopBanner from "../../Components/ShopCompnents/Banner/ShopBanner";
import ContactSection from "../../Components/ShopCompnents/Contact/ContactSection";
import sectionImg from "../../assets/headimg.svg";
// import demoAvatar from "../../assets/avatar.png";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
// import { AllCategories } from "../../Components/ShopCompnents/CategoriesSwipper/CategoryData";
import ChooseUs from "../../Components/ChooseUs/ChooseUs";
import OurStory from "../../Components/OurStory";
import Partners from "../../Components/Partners/Partners";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

const Home = ({ categories }) => {
  const navigate = useNavigate();

  return (
    <div className='Home-page'>
      <div className='section-swipper'>
        <div className='wrapper'>
          <Swiper
            loop={true}
            navigation={true}
            pagination={true}
            modules={[Pagination, Navigation]}
            className='mySwiper'>
            {slides?.map((slide, index) => (
              <SwiperSlide key={index}>
                <div
                  className={`inner-swipper-wrapper ${
                    index === 0 ? "one" : index === 1 ? "two" : ""
                  }`}>
                  <div className='slide-text-wrapper'>
                    <div dangerouslySetInnerHTML={{ __html: slide.header }} />
                    <p className='mini-text'>{slide.subText}</p>
                    {slide.ctaBttn && (
                      <Button
                        className='slide-bttn'
                        onClick={() => navigate(`${slide.url}`)}>
                        {slide.ctaBttn}
                      </Button>
                    )}
                  </div>
                  <motion.img
                    src={slide.img}
                    style={{ marginTop: `${slide?.margin}` }}
                    alt='slide-img'
                    initial={{ opacity: 0, x: 60 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true }}
                    transition={{ ease: "easeOut", duration: 0.3 }}
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
      <Partners />
      <div className='section-three'>
        <div className='wrapper'>
          <div className='title-wrap'>
            <span>Explore the Breadth of Our Selection</span>
            <p>Catering to Each and Every Culinary Need</p>
          </div>
          <CategoriesFilter categories={categories} />
          <Button className='shopCat-bttn' onClick={() => navigate("/catalog")}>
            Browse our catalog
            <NavigateNextIcon />
          </Button>
        </div>
      </div>
      <div className='banner-wrapper'>
        <ShopBanner customStyle={{ paddingRight: "8.8%" }} />
      </div>
      <OurStory />
      <ShopBrands />
      <ChooseUs />
      <div className='promoted-category-section'>
        <motion.div
          className='wrapper'
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true, amount: 0.5 }}
          transition={{ ease: "easeOut", duration: 0.3 }}>
          <div className='top-div'>
            <p>Online Catalog</p>
            <Button onClick={() => navigate("/catalog")}>
              Browse our catalog
              <ArrowForwardIosIcon
                style={{ fontSize: "16px", paddingLeft: "10px" }}
              />
            </Button>
          </div>
          <div className='subCat-swipper'>
            <div className='overlay'>
              <Swiper
                slidesPerView={"auto"}
                spaceBetween={24}
                className='mySwiper'>
                {categories.map(
                  (subcat) =>
                    subcat.children?.length > 1 && (
                      <SwiperSlide key={subcat.parent}>
                        <div
                          className='inner-box-slide'
                          onClick={() =>
                            navigate(`/catalog/${subcat?.parent}`)
                          }>
                          <img
                            src={subcat?.children[0].image}
                            alt={subcat.parent}
                          />
                          <p>{subcat.parent}</p>
                        </div>
                      </SwiperSlide>
                    )
                )}
              </Swiper>
            </div>
          </div>
          <Button className='mobile-bttn' onClick={() => navigate("/catalog")}>
            See All Categories{" "}
            <ArrowForwardIosIcon
              style={{ fontSize: "16px", paddingLeft: "10px" }}
            />
          </Button>
        </motion.div>
      </div>
      <div className='grow-section'>
        <div className='wrapper'>
          <motion.div
            className='title-wrapper'
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true, amount: 0.5 }}
            transition={{ ease: "easeOut", duration: 0.3 }}>
            <img src={sectionImg} alt='section-img' />
            <p className='title'>Succeed with Toronto Sun Wah</p>
          </motion.div>
          <motion.div className='box-wrapper'>
            <div className='inside-box'>
              <div className='top'>
                <span className='red-text'>Starting Up</span>
                <p>Elevate Your Business with Expert Insights</p>
              </div>
              <div className='bottom'>
                <span>
                  Grow your business with confidence. Our boots-on-the-ground
                  can tell you what works, what doesn’t work, and the latest
                  trends and innovations, helping you stay abreast and ahead of
                  your competition.
                </span>
                <Button onClick={() => navigate("/contact")}>
                  Get started now
                </Button>
              </div>
            </div>
            <div className='inside-box'>
              <div className='top'>
                <span className='red-text'>Partnering Up</span>
                <p>Join Our Network, Realize Your Vision</p>
              </div>
              <div className='bottom'>
                <span>
                  Have a great idea. Have a chat with us. Explore co-investment
                  opportunities and kick-start your business venture today.
                </span>
                <Button onClick={() => navigate("/contact")}>
                  Inquire within
                </Button>
              </div>
            </div>
            <div className='inside-box'>
              <div className='top'>
                <span className='red-text'>
                  Marketing Collaboration <br /> Promote Your Brand with Us
                </span>
                <p>Amplify Your Brand Presence</p>
              </div>
              <div className='bottom'>
                <span>
                  Boost your brand and reach. Let's work together to create
                  impactful marketing campaigns that resonate with your target
                  audience. Explore win-win marketing collaborations with us.
                </span>
                <Button onClick={() => navigate("/contact")}>
                  Collaborate for Success
                </Button>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
      {/* <div className="testmonials">
        <motion.div
          className="wrapper"
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true, amount: 0.5 }}
          transition={{ ease: "easeOut", duration: 0.3 }}>
          <p className="sec-title">Testimonials</p>
          <div className="testmonial-swipper">
            <Swiper
              slidesPerView={"auto"}
              spaceBetween={30}
              className="mySwiper">
              {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((slide, index) => (
                <SwiperSlide key={index}>
                  <div className="inner-swipper">
                    <p>
                      “I've been using this web hosting service for over a year
                      and I'm really impressed with the uptime and support. The
                      website has never gone down and the customer service is
                      always quick to help with any issues I have. Highly
                      recommend!”
                    </p>
                    <Rating value={5} />
                    <div className="user-wrapper">
                      <Avatar
                        src={demoAvatar}
                        sx={{ width: "50px", height: "50px" }}
                      />
                      <p className="name">Jane Smith</p>
                      <p className="role">Freelance Designer</p>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </motion.div>
      </div> */}
      <ContactSection />
    </div>
  );
};

export default Home;
