import React, { useEffect, useState } from "react";
import "./SingleProduct.scss";
import { Link, useParams, useNavigate } from "react-router-dom";
import CircleIcon from "@mui/icons-material/Circle";
import {
  getCachedProducts,
  fetchLocalProducts,
} from "../../utils/unleashedAPI";
import { Button, IconButton } from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import placeholder from "../../assets/productph.jpg";
import useCartStore from "../../utils/useCartStore";
import { ToastContainer, toast } from "react-toastify";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
const stepperData = [
  {
    id: 1,
    title: "Home",
    url: "/",
  },
  {
    id: 2,
    title: "Categories",
    url: "/catalog",
  },
];

const SingleProduct = ({ categories }) => {
  const { ProductCode } = useParams();
  const [product, setProduct] = useState(null); // State to store the product data
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  const navigate = useNavigate(); // Initialize navigate

  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [categoryImage, setCategoryImage] = useState();

  const [added, setAdded] = useState(false);
  const [quantity, setQuantity] = useState(1);
  // Use the addToCart function from the Zustand store
  const addToCart = useCartStore((state) => state.addToCart);

  const notify = () => toast.success("Added To Cart!", { autoClose: 2000 });

  const handleDecrement = (e) => {
    e.stopPropagation();
    if (quantity > 1) {
      setQuantity((prev) => prev - 1);
    }
  };

  const handleIncrement = (e) => {
    e.stopPropagation();
    setQuantity((prev) => prev + 1);
  };

  const handleAddToCart = (e) => {
    e.stopPropagation();
    addToCart(product, quantity);
    setAdded(true);
    notify(); // Trigger toast notification
    setTimeout(() => {
      setAdded(false);
    }, 2500);
  };

  const openLightbox = (imageUrl) => {
    setSelectedImage(imageUrl);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
    setSelectedImage(null);
  };

  const goBack = () => {
    navigate(-1); // Navigate to the previous page
  };

  useEffect(() => {
    const fetchProductFromCache = async () => {
      try {
        setLoading(true);

        // Try getting cached products from localStorage
        let cachedProducts = getCachedProducts();

        if (!cachedProducts) {
          // Fallback: Fetch local products if no cached products are found
          cachedProducts = await fetchLocalProducts();

          // Cache the products for future use
          if (cachedProducts) {
            localStorage.setItem(
              "cachedProducts",
              JSON.stringify(cachedProducts)
            );
          }
        }

        if (cachedProducts) {
          // Find the product by ProductCode
          const product = cachedProducts.find(
            (p) => p.ProductCode === ProductCode
          );

          if (!product) {
            throw new Error("Product not found");
          }

          setProduct(product); // Set the product
        } else {
          throw new Error("No products found");
        }
      } catch (err) {
        setError(err.message); // Set error if something goes wrong
      } finally {
        setLoading(false); // Stop loading
      }
    };

    if (ProductCode) {
      fetchProductFromCache(); // Fetch product from cache or local file
    }
  }, [ProductCode]);

  //Review this later to show the image without  double rendering
  useEffect(() => {
    // Split the product group's name to find parent and child
    const productGroup = product?.ProductGroup?.GroupName;
    if (product) {
      const [parentCategory, childCategory] = productGroup?.split(" - ");

      // Find the corresponding category data
      const categoryData = categories?.find(
        (category) => category.parent === parentCategory
      );

      // If categoryData is found, get the image from its children
      const categoryImage = categoryData?.children?.find(
        (child) => child.GroupName === childCategory
      )?.image;

      setCategoryImage(categoryImage);
    }
  }, [product]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!product) return <div>Product not found</div>;

  return (
    <div className='SingleProduct'>
      <ToastContainer />
      <div className='shopStepper'>
        <p
          className='stepper-title nounderline backarrow'
          onClick={goBack} // Attach the goBack handler
          style={{ cursor: "pointer" }}>
          <ArrowBackIcon />
        </p>
        {stepperData.map((data, index) => (
          <Link to={data.url} className='stepper-title' key={index}>
            {index !== 0 && <CircleIcon className='nested-icon' />}{" "}
            {data.title || "N/A"}
          </Link>
        ))}
        {product.ProductGroup && (
          <p className='stepper-title nounderline'>
            <CircleIcon className='nested-icon' />
            {product.ProductGroup.GroupName}
          </p>
        )}
        <p className='stepper-title nounderline'>
          <CircleIcon className='nested-icon' />
          {product.ProductDescription}
        </p>
      </div>
      <div className='product_card'>
        {lightboxOpen && (
          <div className='lightbox' onClick={closeLightbox}>
            <div
              className='lightbox-content'
              onClick={(e) => e.stopPropagation()}>
              <img src={selectedImage} alt='Selected' />
              <button className='lightbox-close' onClick={closeLightbox}>
                &times;
              </button>
            </div>
          </div>
        )}
        <div className='left'>
          <img
            src={product.ImageUrl || placeholder}
            alt={`${product.ProductDescription}`}
            className='primaryImage'
            onClick={() =>
              product.ImageUrl ? openLightbox(product.ImageUrl) : null
            }
          />
          {product.Images && (
            <div className='gallery'>
              {product.Images?.map((image) => (
                <img
                  src={image.Url}
                  alt={`${product.ProductDescription} images`}
                  key={image.Url}
                  onClick={() => openLightbox(image.Url)}
                />
              ))}
            </div>
          )}
        </div>
        <div className='right'>
          <div className='upper'>
            <p className='productname'>{product.ProductDescription}</p>
            {product.ProductCode && (
              <div className='attribute'>
                <p className='title'>Product Code</p>
                <p className='value'>{product.ProductCode}</p>
              </div>
            )}
            {product.AttributeSet?.Attributes?.find(
              (attr) => attr.Name === "Brand"
            )?.Value && (
              <div className='attribute'>
                <p className='title'>Brand</p>
                <p className='value'>
                  {product.AttributeSet?.Attributes?.find(
                    (attr) => attr.Name === "Brand"
                  )?.Value || "Brand not found"}
                </p>
              </div>
            )}
            {product.ProductGroup && (
              <div className='attribute'>
                <p className='title'>Category</p>
                <p className='value'>
                  {categoryImage && <img src={categoryImage} alt='category' />}{" "}
                  {product.ProductGroup.GroupName}
                </p>
              </div>
            )}
          </div>
          <div className='product-control'>
            <div className='quantity-box'>
              <IconButton className='decrement-bttn' onClick={handleDecrement}>
                <RemoveIcon />
              </IconButton>
              <span className='product-qty'>{quantity}</span>
              <IconButton className='increment-bttn' onClick={handleIncrement}>
                <AddIcon />
              </IconButton>
            </div>
            <Button
              className={`add-to-cart-bttn ${added ? "added" : ""}`}
              onClick={handleAddToCart}>
              <FavoriteBorderIcon />
              {added ? "Added" : "Add"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleProduct;
