import React from "react";
import "./ShopBrands.scss";
import { Brands } from "./brands";
import { Button, useMediaQuery } from "@mui/material";
import { SwiperSlide, Swiper } from "swiper/react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { Autoplay } from "swiper/modules";

const ShopBrands = () => {
  const matches = useMediaQuery("(max-width:960px)");
  const navigate = useNavigate();

  return (
    <>
      <div className='title-banner'>
        <motion.div
          className='background-title'
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true, amount: 0.5 }}
          transition={{ ease: "easeOut", duration: 0.3 }}>
          <div className='overlay'></div>
          <p>Harness Five Decades of Expertise</p>
        </motion.div>
      </div>
      <motion.div
        className='brands-section'
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true, amount: 0.5 }}
        transition={{ ease: "easeOut", duration: 0.3 }}>
        <div className='brands-wrapper'>
          <div className='section-header'>
            <p className='section-title'>Authenticity, Integrity, and Trust</p>
            <p className='section-subtext'>
              We Build Business Relationships on These 3 Principles
            </p>
          </div>
          <p className='mini-text'>
            Our portfolio ranges from established legacy brands to promising
            niche brands. Explore our brand catalog and find the perfect
            ingredient that best suits your needs.
          </p>
          {matches ? (
            <Swiper
              slidesPerView={"auto"}
              spaceBetween={29.67}
              speed={3000}
              modules={[Autoplay]}
              autoplay={{
                delay: 3000,
              }}
              loop={true}
              style={{ width: "100%" }}>
              {Brands.map((brand, index) => (
                <SwiperSlide key={index}>
                  <div>
                    <img src={brand.src} className='brand-img' />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          ) : (
            <div className='boxes-wrapper'>
              {Brands.map((brand) => (
                <div className='brand-box' key={brand.id}>
                  <img src={brand.src} alt='brand' />
                  {/* <p className='brand-name'>{brand.name}</p> */}
                </div>
              ))}
            </div>
          )}
        </div>
      </motion.div>
    </>
  );
};

export default ShopBrands;
