export const Partners = [
  {
    id: 14,
    img: './Images/partner14.svg',
  },
  {
    id: 1,
    img: './Images/partner1.svg',
  },
  {
    id: 2,
    img: './Images/partner2.svg',
  },
  {
    id: 3,
    img: './Images/partner3.svg',
  },
  {
    id: 4,
    img: './Images/partner4.svg',
  },
  {
    id: 5,
    img: './Images/partner5.svg',
  },
  {
    id: 6,
    img: './Images/partner6.svg',
  },
  {
    id: 7,
    img: './Images/partner7.svg',
  },
  {
    id: 8,
    img: './Images/partner8.svg',
  },
  {
    id: 9,
    img: './Images/partner9.svg',
  },
  {
    id: 10,
    img: './Images/partner10.svg',
  },
  {
    id: 11,
    img: './Images/partner11.svg',
  },
  {
    id: 12,
    img: './Images/partner12.svg',
  },
  {
    id: 13,
    img: './Images/partner13.svg',
  },
];
