import React from "react";
import "./ShopBanner.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/pagination";
import { Pagination, Navigation } from "swiper/modules";
import bannerOne from "../../../assets/Slide1banner.svg";
import bannerTwo from "../../../assets/bannerTwo.svg";
import bannerThree from "../../../assets/bannerThree.svg";
import bannerFour from "../../../assets/bannerFour.svg";
import { Button, useMediaQuery } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate } from "react-router-dom";
import { Autoplay } from "swiper/modules";

const ShopBanner = ({ customStyle }) => {
  const isMobile = useMediaQuery("(max-width:1000px)");
  const navigate = useNavigate();

  return (
    <div className='nested-banner'>
      <Swiper
        pagination={true}
        modules={[Pagination, Autoplay, Navigation]}
        loop={true}
        navigation={true}
        speed={3000}
        className='mySwiper'
        autoplay={{
          delay: 3000,
        }}
        style={{
          width: "100%",
        }}>
        <SwiperSlide>
          <div
            className='overlay'
            style={customStyle && !isMobile ? customStyle : {}}>
            <div className='text-wrapper'>
              <p className='title'>Craft Perfect Sushi</p>
              <span className='subtext'>
                Stock up on essential sushi ingredients
              </span>
              <Button
                className='banner-bttn'
                onClick={() => navigate("/catalog")}>
                Request for pricing today
                <ArrowForwardIosIcon style={{ fontSize: "16px" }} />
              </Button>
            </div>
            <img src={bannerOne} alt='banner' />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div
            className='overlay'
            style={customStyle && !isMobile ? customStyle : {}}>
            <div className='text-wrapper'>
              <p className='title'>Savor Authentic Flavours</p>
              <span className='subtext'>
                All You Need for a Heartwarming Bowl{" "}
              </span>
              <Button
                className='banner-bttn'
                onClick={() => navigate("/catalog")}>
                Request for pricing today{" "}
                <ArrowForwardIosIcon style={{ fontSize: "16px" }} />
              </Button>
            </div>
            <img src={bannerTwo} alt='banner' />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div
            className='overlay'
            style={customStyle && !isMobile ? customStyle : {}}>
            <div className='text-wrapper'>
              <p className='title'>Roll into Freshness</p>
              <span className='subtext'>Get everything for Spring Rolls </span>
              <Button
                className='banner-bttn'
                onClick={() => navigate("/catalog")}>
                Request for pricing today{" "}
                <ArrowForwardIosIcon style={{ fontSize: "16px" }} />
              </Button>
            </div>
            <img src={bannerThree} alt='banner' />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div
            className='overlay'
            style={customStyle && !isMobile ? customStyle : {}}>
            <div className='text-wrapper'>
              <p className='title'>Chicken Fried Rice</p>
              <span className='subtext'>Everything for a Flavorful Dish </span>
              <Button
                className='banner-bttn'
                onClick={() => navigate("/catalog")}>
                Request for pricing today{" "}
                <ArrowForwardIosIcon style={{ fontSize: "16px" }} />
              </Button>
            </div>
            <img src={bannerFour} alt='banner' />
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default ShopBanner;
