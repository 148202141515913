import React from "react";
import "./Partners.scss";
import PartnersSwipper from "../PartnersSwipper/PartnersSwipper";

const Partners = () => {
  return (
    <div className='section-two'>
      <div className='wrapper'>
        <p className='trusted-title'>
          Trusted by Some of the Most Established Names
        </p>
        <PartnersSwipper />
      </div>
    </div>
  );
};

export default Partners;
