import React, { Suspense, useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import Home from "./Pages/Home/Home";
import Layout from "./Components/Layout/Layout";
import { CircularProgress } from "@mui/material";
import Careers from "./Pages/Careers/Careers";
import Contact from "./Pages/Contact/Contact";
import Notfound from "./Pages/Notfound/Notfound";
import Quote from "./Pages/Quote/Quote";
import MultiYear from "./Pages/MultiYear/MultiYear";
import Privacy from "./Pages/Privacy/Privacy";
import Terms from "./Pages/Privacy/Terms";
import { fetchCategories } from "./utils/unleashedAPI";
import DAIRYEGG from "./assets/DAIRY & EGG.svg";
import Beverage from "./assets/Beverage.svg";
import Dessert from "./assets/Dessert.svg";
import PRESERVED from "./assets/PREPAREDFOOD.svg";
import PREPARED from "./assets/PREPARED.svg";
import Rice from "./assets/Rice.svg";
import FoodPreparation from "./assets/FOODPREPARATION.svg";
import Napkins from "./assets/Napkins.svg";
import Bags from "./assets/Bags.svg";
import Trays from "./assets/Trays.svg";
import container from "./assets/container.svg";
import Utensils from "./assets/Utensils.svg";
import beef from "./assets/beef.svg";
import Chicken from "./assets/Chicken.svg";
import other from "./assets/other.svg";
import pork from "./assets/pork.svg";
import SEEFOOD from "./assets/SEEFOOD.svg";
import CONDIMENTSAUCE from "./assets/CONDIMENTSAUCE.svg";
import FLOURSTARCH from "./assets/FLOURSTARCH.svg";
import COOKINGFAT from "./assets/COOKINGFAT.svg";
import FRESHVEGETABLE from "./assets/FRESHVEGETABLE.svg";
import SEASONINGSPICE from "./assets/SEASONING & SPICE.svg";
import FreshFruit from "./assets/FreshFruit.svg";
import Frozen from "./assets/Frozen.svg";
import CHEMICAL from "./assets/CHEMICAL.svg";
import SANITATIONSUPPLIES from "./assets/SANITATION SUPPLIES.svg";
import OTHERS from "./assets/OTHERS.svg";
import BEANSPROUT from "./assets/BEANSPROUT.svg";
import SingleProduct from "./Pages/SingleProduct/SingleProduct";

const AboutUs = React.lazy(() => import("./Pages/About/Company"));
const Shop = React.lazy(() => import("./Pages/Shop/Shop"));

function App() {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const loadCategories = async () => {
      try {
        const fetchedCategories = await fetchCategories();
        const organizedCategories = organizeCategories(fetchedCategories);
        setCategories(organizedCategories);
      } catch (error) {
        console.error("Error loading categories", error);
      }
    };
    loadCategories();
  }, []);

  const imageMapping = {
    "DAIRY & EGG": DAIRYEGG,
    "DESSERT & SNACK": Dessert,
    BEVERAGE: Beverage,
    "PREPARED FOOD": PREPARED,
    "RICE & NOODLE": Rice,
    UTENSIL: Utensils,
    "PRESERVED FOOD": PRESERVED,
    CONTAINER: container,
    TRAY: Trays,
    BAG: Bags,
    NAPKIN: Napkins,
    "FOOD PREPARATION": FoodPreparation,
    CHICKEN: Chicken,
    BEEF: beef,
    PORK: pork,
    SEAFOOD: SEEFOOD,
    OTHER: other,
    "CONDIMENT & SAUCE": CONDIMENTSAUCE,
    "FLOUR & STARCH": FLOURSTARCH,
    "COOKING OIL & FAT": COOKINGFAT,
    "SEASONING & SPICE": SEASONINGSPICE,
    "FRESH VEGETABLE": FRESHVEGETABLE,
    "FRESH FRUIT": FreshFruit,
    "FROZEN FRUIT & VEGETABLE": Frozen,
    "SANITATION SUPPLIES": SANITATIONSUPPLIES,
    "CLEANING CHEMICAL": CHEMICAL,
    "BEAN SPROUT": BEANSPROUT,
  };

  const organizeCategories = (categories) => {
    const organizedCategories = {};

    categories.forEach((category) => {
      const [parent, child] = category.GroupName.split(" - ");

      if (!organizedCategories[parent]) {
        organizedCategories[parent] = [];
      }

      const subcategoryImage = imageMapping[child] || OTHERS; // Use default image if not found in mapping

      organizedCategories[parent].push({
        GroupName: child || parent,
        Guid: category.Guid,
        LastModifiedOn: category.LastModifiedOn,
        image: subcategoryImage,
      });
    });

    return Object.keys(organizedCategories).map((parent) => ({
      parent,
      children: organizedCategories[parent],
    }));
  };

  return (
    <div className='App'>
      <I18nextProvider i18n={i18n}>
        <BrowserRouter>
          <Layout categories={categories}>
            <Routes path='/'>
              <Route index element={<Home categories={categories} />} />
              <Route
                path='careers'
                element={
                  <Suspense
                    fallback={
                      <div
                        style={{
                          width: "100vw",
                          height: "100vh",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}>
                        <CircularProgress />
                      </div>
                    }>
                    <Careers />
                  </Suspense>
                }
              />
              <Route
                path='contact'
                element={
                  <Suspense
                    fallback={
                      <div
                        style={{
                          width: "100vw",
                          height: "100vh",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}>
                        <CircularProgress />
                      </div>
                    }>
                    <Contact />
                  </Suspense>
                }
              />
              <Route path='catalog'>
                <Route
                  index
                  element={
                    <Suspense
                      fallback={
                        <div
                          style={{
                            width: "100vw",
                            height: "100vh",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}>
                          <CircularProgress />
                        </div>
                      }>
                      <Shop categories={categories} />
                    </Suspense>
                  }
                />
                <Route
                  path='product/:ProductCode'
                  element={<SingleProduct categories={categories} />}
                />

                <Route path=':catId'>
                  <Route
                    index
                    element={
                      <Suspense
                        fallback={
                          <div
                            style={{
                              width: "100vw",
                              height: "100vh",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}>
                            <CircularProgress />
                          </div>
                        }>
                        <Shop categories={categories} />
                      </Suspense>
                    }
                  />
                  <Route
                    path=':subCatId'
                    element={
                      <Suspense
                        fallback={
                          <div
                            style={{
                              width: "100vw",
                              height: "100vh",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}>
                            <CircularProgress />
                          </div>
                        }>
                        <Shop categories={categories} />
                      </Suspense>
                    }
                  />
                </Route>
              </Route>
              <Route
                path='/company'
                element={
                  <Suspense
                    fallback={
                      <div
                        style={{
                          width: "100vw",
                          height: "100vh",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}>
                        <CircularProgress />
                      </div>
                    }>
                    <AboutUs />
                  </Suspense>
                }
              />
              <Route path='/MultiYear' element={<MultiYear />} />
              <Route path='/quote' element={<Quote />} />
              <Route path='/privacy' element={<Privacy />} />
              <Route path='/Terms' element={<Terms />} />
              <Route path='/*' element={<Notfound />} />
            </Routes>
          </Layout>
        </BrowserRouter>
      </I18nextProvider>
    </div>
  );
}

export default App;
